<template>
  <div>
    <p class="error" v-if="noFrontCamera">
      You don't seem to have a front camera on your device
    </p>

    <p class="error" v-if="noRearCamera">
      You don't seem to have a rear camera on your device
    </p>
    <QrcodeStream
      v-show="visible"
      camera="camera"
      @decode="onDecode"
      @init="onInit"
    />
    <p class="error--text">{{ error }}</p>
    <p v-if="showResult">
      <b>result:{{ result }}</b>
    </p>
  </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";

export default {
  name: "QrReader",
  components: {
    QrcodeStream
  },
  props: {
    value: { type: String, default: null },
    visible: { type: Boolean, default: true },
    showResult: Boolean
  },
  data() {
    return {
      result: "",
      error: "",
      camera: "front"
    };
  },
  watch: {
    error: function(v) {
      this.$emit("error", v);
    }
  },
  methods: {
    onDecode(result) {
      this.result = result;
      this.$emit("input", result);
      this.$emit("decode", result);
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        console.error(error);
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: you need to grant camera access permisson";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        }
      }
    }
  }
};
</script>
